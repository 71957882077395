<template>
  <v-container>
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step
          :complete="step > 1"
          :editable="step > 1 && step < 3"
          step="1"
        >
          Benutzer
        </v-stepper-step>

        <v-stepper-step
          :complete="step > 2"
          step="2"
        >
          Einladen
        </v-stepper-step>

        <v-stepper-step
          :complete="step > 3"
          step="3"
        >
          Resultat
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card height="500px" class="py-5">
            <v-alert
              type="warning"
            >
              Wichtig: Bestehende Passwörter werden überschrieben!
            </v-alert>

            <v-select
              v-model="selectedUsers"
              :items="users.map(u => ({ ...u, text: u.name, value: u.id }))"
              label="Benutzer wählen"
              return-object
              multiple
              outlined
            />
          </v-card>

          <div class="text-right mb-1">
            <v-btn :disabled="selectedUsers.length === 0" color="primary" @click="step++">Weiter</v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card height="500px" class="py-5">
            <v-text-field
              v-model="subject"
              label="E-Mail Betreff"
              outlined
            />
            <v-textarea
              v-model="text"
              label="E-Mail Text"
              rows="10"
              outlined
            />
            <v-alert
              type="info"
              color="grey"
              dense
            >
              Die Platzhalter {EMAIL} und {PASSWORD} werden beim Versand ersetzt.
            </v-alert>
          </v-card>

          <div class="text-right mb-1">
            <v-btn class="mr-4" @click="step = 1">Zurück</v-btn>
            <v-btn :disabled="!subject || !text" :loading="saving" color="primary" @click="inviteUsers">{{ selectedUsers.length }} Einladungen versenden</v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card height="500px py-15">
            <div class="text-center">{{ selectedUsers.length }} Einladungen versandt.</div>
          </v-card>

          <div class="text-right mb-1">
            <v-btn color="primary" @click="$emit('close')">Schliessen</v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
export default {
  name: 'UsersInvite',
  data () {
    return {
      step: 1,
      users: null,
      selectedUsers: [],
      subject: 'Deine Zugangsdaten zur ' + this.$cfg.appTitle,
      text: 'Herzlich Willkommen\n\nURL: ' + this.$cfg.appUrl + '\n\nE-Mail: {EMAIL}\nPasswort: {PASSWORD}\n\nLiebe Grüsse\nDein ' + this.$cfg.appTitle + ' Team\n',
      saving: false
    }
  },
  methods: {
    async inviteUsers () {
      this.saving = true
      const inviteUsersFunc = this.$fb.fn.httpsCallable('inviteUsers')
      const response = await inviteUsersFunc({
        users: this.selectedUsers,
        subject: this.subject,
        text: this.text
      })
      if (response.data.ok) {
        this.step = 3
      }
      this.saving = false
    }
  },
  created () {
    this.$fb.db.collection('users')
      .orderBy('name')
      .get()
      .then(snap => {
        const users = []
        snap.forEach(doc => {
          const user = { id: doc.id, ...doc.data() }
          if (user.email && !user._disabled) {
            users.push(user)
          }
        })
        this.users = users
      })
  }
}
</script>
