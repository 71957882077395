<template>
  <v-container>
    <v-toolbar flat dense rounded color="grey lighten-3" class="mb-4">
      <v-btn text @click="add">Hinzufügen</v-btn>
      <v-btn text @click="importDlg = true">Importieren</v-btn>
      <v-btn text @click="inviteDlg = true">Einladen</v-btn>
    </v-toolbar>

    <v-simple-table>
      <thead>
        <tr>
          <th width="24"></th>
          <th class="text-left">Name</th>
          <th class="text-left">E-Mail</th>
          <th class="text-left">Mobile</th>
          <th v-if="$root.groups" class="text-left">Gruppen</th>
          <th width="150" class="text-left">Erstellt</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in users"
          :key="item.id"
          :style="item._disabled ? 'color: lightgrey' : ($cfg.signUp && $cfg.signUp.moderated && !item.reviewed ? 'color: orange' : '')"
          @click="user = { uid: item.id, claims: {}, ...item }"
        >
          <td><v-icon>{{ 'mdi-account' + (item._disabled ? '-off' : ($cfg.signUp && $cfg.signUp.moderated && !item.reviewed ? '-eye' : '')) }}</v-icon></td>
          <td>{{ item.name }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.telm | tel }}</td>
          <td v-if="$root.groups">{{ item.claims && item.claims.groupIds ? renderGroups(item.claims.groupIds) : '' }}</td>
          <td>{{ item.ts | date }}</td>
        </tr>
      </tbody>
    </v-simple-table>

    <fab-btn @click="add"/>

    <v-dialog
      v-if="user !== null"
      :value="true"
      persistent
      scrollable
      width="600"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ user.name }}</v-toolbar-title>
          <v-spacer/>
          <v-btn icon dark @click="user = null">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="py-5">
          <user-profile-edit v-model="user" admin>
            <div class="text-center text-overline mt-8 mb-3">Einstellungen</div>
            <v-select
              v-if="$root.groupOpts"
              v-model="user.claims.groupIds"
              :items="$root.groupOpts"
              label="Gruppen"
              chips
              multiple
            ></v-select>
            <v-text-field v-model="user.password" label="Passwort" type="password" hint="Mind. 6 Zeichen" autocomplete="new-password"/>
            <div v-if="user.uid">
              <div v-if="!($cfg.signUp && $cfg.signUp.moderated) || user.reviewed">
                <v-checkbox v-model="user._disabled" label="Archiviert (Login gesperrt und nicht mehr aufgeführt)"/>
              </div>
              <div v-else class="text-center">
                <div class="text-overline mt-8">Login beantragt</div>
                <div><v-btn color="success" :loading="saving" :disabled="!valid" class="my-3" @click="user.reviewed = true; save()">Akzeptieren und Zugang zur App erlauben</v-btn></div>
                <div><v-btn color="error" :loading="saving" class="my-3" @click="user._disabled = true; save()">Ablehnen und Zugang zur App verweigern</v-btn></div>
              </div>
            </div>
          </user-profile-edit>
        </v-card-text>
        <v-card-actions class="d-flex justify-center py-5">
          <div><v-btn color="primary" :loading="saving" :disabled="!valid" @click="save">Speichern</v-btn></div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="importDlg"
      :value="true"
      fullscreen
      hide-overlay
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Importieren</v-toolbar-title>
          <v-spacer/>
          <v-btn icon dark @click="importDlg = null">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="py-5">
          <users-import @close="importDlg = null"/>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="inviteDlg"
      :value="true"
      fullscreen
      hide-overlay
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Einladen</v-toolbar-title>
          <v-spacer/>
          <v-btn icon dark @click="inviteDlg = null">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="py-5">
          <users-invite @close="inviteDlg = null"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import UserProfileEdit from '@/components/base/UserProfileEdit'
import FabBtn from '@/components/base/FabBtn'
import UsersImport from '@/components/admin/UsersImport'
import UsersInvite from '@/components/admin/UsersInvite'

export default {
  name: 'UsersAdmin',
  components: { UserProfileEdit, FabBtn, UsersImport, UsersInvite },
  props: {
    config: Object
  },
  data () {
    return {
      users: null,
      user: null,
      loading: false,
      saving: false,
      importDlg: false,
      inviteDlg: false
    }
  },
  computed: {
    valid () {
      return this.user && this.user.forename && this.user.lastname && (!this.user.email || /\S+@\S+\.\S+/.test(this.user.email))
    }
  },
  methods: {
    renderGroups (groupIds) {
      return groupIds.map(groupId => this.$root.groups[groupId]?.name || null).filter(g => !!g).sort().join(', ')
    },
    subscribe () {
      this.loading = true
      this.users = null

      if (this.unsubscribe) {
        this.unsubscribe()
      }

      this.unsubscribe = this.$fb.db.collection('users')
        .onSnapshot(snap => {
          const users = []
          snap.forEach(doc => {
            users.push({
              id: doc.id,
              ...doc.data()
            })
          })
          const orderBy = 'name'
          users.sort((a, b) => a[orderBy] < b[orderBy] ? -1 : 1)
          this.users = users
          this.loading = false
        })
    },
    add () {
      const user = { claims: {} }
      user.ok = true // auto ok (skip registration steps)
      user.tagIds = Object.keys(this.$root.tags || []) // auto subscribe to all tags currently available
      user.public = this.$cfg.signUp?.publicDefaults || {}
      if (this.$cfg.signUp?.moderated) {
        user.reviewed = true // auto reviewed
      }
      this.user = user
    },
    async save () {
      this.saving = true
      const user = { ...this.user }
      delete user.id
      if (!this.$cfg.signUp?.nameEditable) {
        user.name = (user.forename + ' ' + user.lastname).trim()
      }
      const saveUserFunc = this.$fb.fn.httpsCallable('saveUser')
      const response = await saveUserFunc({
        id: this.user.id || null,
        user
      })
      this.saving = false
      if (response.data.ok) {
        this.user = null
      }
    }
  },
  created () {
    this.subscribe()
  },
  beforeDestroy () {
    this.unsubscribe()
  }
}
</script>
