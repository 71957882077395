<template>
  <v-container>
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step
          :complete="step > 1"
          :editable="step > 1 && step < 3"
          step="1"
        >
          Datei
        </v-stepper-step>

        <v-stepper-step
          :complete="step > 2"
          step="2"
        >
          Vorschau
        </v-stepper-step>

        <v-stepper-step
          :complete="step > 3"
          step="3"
        >
          Resultat
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card height="400px">
            <div class="mb-5">
              <v-icon class="mr-2">$file</v-icon>
              <a href="/static/Unite_UsersImport.xlsx" target="_blank">Import-Datei Vorlage</a>
            </div>

            <div>
              <v-file-input
                v-model="file"
                label="Import-Datei (.xlsx)"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </div>
          </v-card>

          <div class="text-right mb-1">
            <v-btn :disabled="!file" :loading="saving" color="primary" @click="importFile">Weiter</v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card height="400px">
            <v-simple-table
              fixed-header
              height="300px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Nr</th>
                    <th>Name</th>
                    <th>E-Mail</th>
                    <th>Mobile</th>
                    <th width="80" class="text-right">{{ dataValid ? 'Importiert' : 'Ungültig' }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in data" :key="item.nr">
                    <td>{{ item.nr }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.telm }}</td>
                    <td v-if="dataValid" class="text-center"><v-icon v-if="item.imported !== null" :color="item.imported ? 'success' : 'error'">{{ item.imported ? 'mdi-check-circle' : 'mdi-alert-circle' }}</v-icon></td>
                    <td v-else class="text-center"><v-icon v-if="!item.valid" color="error">mdi-alert-circle</v-icon></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>

          <div class="text-right mb-1">
            <v-btn class="mr-4" @click="step = 1">Zurück</v-btn>
            <v-btn :disabled="!dataValid" :loading="saving" color="primary" @click="importData">Importieren</v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card height="400px">
            <div>Import fertiggestellt.</div>

            <v-alert
              v-if="failedRows && failedRows.length > 0"
              type="error"
              class="mt-3"
            >
              <div class="error">Fehlerhafte Einträge (nicht importiert):</div>
              <div v-for="item in failedRows" :key="item.nr">
                {{ item.nr}}: {{ item.name }}
              </div>
            </v-alert>
          </v-card>

          <div class="text-right mb-1">
            <v-btn color="primary" @click="$emit('close')">Schliessen</v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
export default {
  name: 'UsersImport',
  data () {
    return {
      step: 1,
      saving: false,
      file: null,
      data: null
    }
  },
  computed: {
    dataValid () {
      return this.data?.filter(row => !row.valid).length === 0
    },
    failedRows () {
      return this.data?.filter(d => d.imported === false)
    }
  },
  methods: {
    async importFile () {
      this.saving = true
      const XLSX = await import(/* webpackChunkName: "xlsx" */ 'xlsx')
      const raw = await this.file.arrayBuffer()
      const workbook = XLSX.read(raw)
      const json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]) || null
      if (json) {
        const data = []
        json.forEach((row, i) => {
          const item = {
            nr: i + 1, // nr = row-nr in excel file
            imported: null,
            ...row
          }
          if (item.telm) {
            item.telm = this.sanitizeTel(item.telm)
          }
          if (item.telp) {
            item.telp = this.sanitizeTel(item.telp)
          }
          if (item.telw) {
            item.telw = this.sanitizeTel(item.telw)
          }
          if (item.street || item.plz || item.city) {
            item.address = {
              street: item.street || '',
              plz: item.plz || '',
              city: item.city || ''
            }
            delete item.street
            delete item.plz
            delete item.city
          }
          if (item.groups) {
            const groupEntries = Object.entries(this.$root.groups)
            item.claims = {
              groupIds: []
            }
            item.groups.split(',').forEach(g => {
              const ge = groupEntries.find(ge => ge[1].name === g.trim())
              if (ge) {
                item.claims.groupIds.push(ge[0])
              }
            })
            delete item.groups
          }
          item.valid = this.validateRow(item)
          data.push(item)
        })
        this.data = data
        this.step = 2
      }
      this.saving = false
    },
    sanitizeTel (tel) {
      if (!tel) return tel
      if (tel[0] === '0') tel = '+41' + tel.substr(1)
      tel = tel.replace(/\s/g, '')
      return tel
    },
    validateRow (row) {
      return row.name &&
        // (row.email || row.telm) && // nologin users can have this empty
        (!row.email || /\S+@\S+\.\S+/.test(row.email)) &&
        (!row.telm || row.telm.substr(0, 1) === '+')
    },
    async importData () {
      this.saving = true
      const saveUserFunc = this.$fb.fn.httpsCallable('saveUser')
      for (let i = 0; i < this.data.length; i++) {
        try {
          const user = { ...this.data[i] }
          delete user.nr
          delete user.valid
          user.ok = true // auto ok (skip registration steps)
          user.tagIds = Object.keys(this.$root.tags || []) // auto subscribe to all tags currently available
          user.public = this.$cfg.signUp?.publicDefaults || {}
          if (this.$cfg.signUp?.moderated) {
            user.reviewed = true // auto reviewed
          }
          const response = await saveUserFunc({ user })
          this.data[i].imported = response.data.ok
        } catch (err) {
          this.data[i].imported = false
          // break
        }
      }
      this.step = 3
      this.saving = false
    }
  }
}
</script>
